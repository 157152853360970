import { useAuth0 } from '@auth0/auth0-react';
import React, { useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query';
import styled from 'styled-components'
import { Button } from "../components/Button";
import { Card } from '../components/Card'
import { Loading } from '../components/Loading';
import { NumberPicker } from '../components/NumberPicker'
import { PackageCard } from '../components/PackageCard'
import { PurchaseOrderItem } from '../components/PurchaseOrderItem'
import { Typography } from '../styles/TextStyles'
import axios, { AxiosError } from 'axios'
import { format, parseISO } from 'date-fns';
import { ColorPaletteMain } from '../styles/ColorPalette';
import { NAVBAR_WIDTH } from '../styles/Constants';
import { spacing } from '../styles/Spacing';
import { Toggle } from '../components/Toggle';
import { useOnScreen } from '../utility/useOnScreen';
import { Modal } from '../components/Modal';


//TODO: add translation, the file for the English version already has a schema defined and filled with *most* items

interface QuotaStatusResponse {
  amountAvailable: number
  amountCurrent: number
  amountTotal: number
  status: string
  expires: string
}

const emptyCart = {
  "package": 0,
  "consumable/signature": 0
}

const formatMoney = (number: number) => {
  return (number / 100).toLocaleString(undefined, { minimumFractionDigits: 2, maximumFractionDigits: 2})
}

export const ManagePurchases:React.FC = () => {

  const { getAccessTokenSilently } = useAuth0();

  const [ useMonthlyPayment, setUseMonthlyPayment] = useState<boolean>(true)

  const [cartContents, setCartContents] = useState<{ [key: string]: number }>(emptyCart)

  const [ comment, setComment ] = useState<string>('')

  const [ showSuccessModal, setShowSuccessModal] = useState(false)

  const [ licensingServerClinicNotFound, setLicenseServerClinicNotFound] = useState<boolean>(false)

  const { isLoading, data } = useQuery({ retry: 0, queryKey: ['quotaStatus/patient'], queryFn: async (): Promise<QuotaStatusResponse | false> => {
    const token = await getAccessTokenSilently({
      audience: 'https://dashboard.hope-app.cloud',
      scope: 'quota:read',
    });

    if (!token) {
      throw new Error('unauthorized')
    }

    try {
      const queryResult = await axios.get <QuotaStatusResponse>(`${process.env.REACT_APP_BACKEND_URL}/quota/status/user/patient`, {
        headers: {
          'Authorization': 'Bearer ' + token
        }
      })

      return queryResult.data
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 404) {
        setLicenseServerClinicNotFound(true)
        return false
      }
      throw e
    }

  }})


  //TODO: write state handling for all order items plus logic to forward to shopping cart
  const packageArray = [{
    title: "Package 1",
    amount: "150",
    description: "New patients",
    cost: "€20 / Patient",
    paymentInfo: {
      once: 3000_00,
      monthly: 250_00
    }

  }, {
    title: "Package 2",
    amount: "300",
    description: "New patients",
    cost: "€19.50 / Patient",
    paymentInfo: {
      once: 5850_00,
      monthly: 487_50
    }
  }, {
    title: "Package 3",
    amount: "600",
    description: "New patients",
    cost: "€19 / Patient",
    paymentInfo: {
      once: 11400_00,
      monthly: 950_00
    }
  }]

  const addOnArray = [ {
    title: "eSignature Envelopes",
    licenseType: 'consumable/signature',
    cost: 3_00,
    costPer: "Envelope",
    costPerPlural: "Envelopes"
  }]

  const poCategories = [
    "date",
    "order no.",
    "total amount",
    "status",
    "invoice",
  ]

  const poArray = [
    {
      date: "21.03.2022",
      orderNumber: "0001",
      amount: "2.500€",
      status: "Processed",
      invoiceLink: "/testlink",
    },
    {
      date: "21.03.2022",
      orderNumber: "0002",
      amount: "2.500€",
      status: "Processed",
      invoiceLink: "/testlink",
    },
    {
      date: "21.03.2022",
      orderNumber: "0003",
      amount: "2.500€",
      status: "Pending",
      invoiceLink: "/testlink",
    },
  ]

  const overviewSectionRef: any = useRef<HTMLDivElement>()

  const overviewSectionOnScreen: boolean = useOnScreen(overviewSectionRef, "-500px")

  const sendOrderMutation = useMutation(async (): Promise<'OK'> => {

    const token = await getAccessTokenSilently({
      audience: 'https://dashboard.hope-app.cloud',
      scope: 'quota:read',
    });

    if (!token) {
      throw new Error('unauthorized')
    }
    return axios.post(`${process.env.REACT_APP_BACKEND_URL}/order/submit`, { orderData: { ...cartContents, comment, useMonthlyPayment }}, {
      headers: {
        'Content-Type': 'application/json',
        'Authorization': 'Bearer ' + token
      },
      timeout: 4000,
    }).then((res) => res.data).then((data) => {
      if (data.status === "ERROR") {
        throw new Error('Server error')
      }

      setCartContents(emptyCart)
      setComment('')
      setShowSuccessModal(true)
      return data
    })
  })

  const submitOrder = () => {
    sendOrderMutation.mutate()
  }

  console.log(data)
  return (
    <ContentWrapper>
      <Modal modalIsShown={showSuccessModal}>
        <ModalTitle>Order submitted</ModalTitle>
        <p style={{ textAlign: 'center' }}>Thank you for your order! Please check your inbox for the confirmation in the next 2 business days.</p>
        <Button onAction={() => { setShowSuccessModal(false) }}>
          Close
        </Button>
      </Modal>
      <h3>Manage Purchases</h3>
      {!!isLoading && <Loading />}
      {!isLoading && (!!data || licensingServerClinicNotFound) && <React.Fragment>
        <CartWrapper shown={!overviewSectionOnScreen && Object.entries(cartContents).findIndex(el => el[1] > 0) !== -1}>
          <div>
            <strong>Your Cart</strong><br />
            {Object.entries(cartContents).map(([type, value]) => {
              if (value > 0) {
                if (type === 'package') {
                  const _package = packageArray[value - 1]
                  return `${_package.title} (${useMonthlyPayment ? 'monthly' : 'once-off'})`
                }
                const licenseType = addOnArray.find(el => el.licenseType === type)
                if (!licenseType) {
                  return
                }
                return `${value} ${value === 1 ? licenseType.costPer : licenseType.costPerPlural}`
              }
              return null
            }).filter(n => n).join(', ')}
          </div>
          <div>
            <Button onAction={() => overviewSectionRef?.current.scrollIntoView()}>
              Review order
            </Button>
          </div>
        </CartWrapper>
        <SectionTitle>Current Usage</SectionTitle>

        {licensingServerClinicNotFound && <p style={{ marginTop: '-32px'}}>Current usage unavailable. If hope has been recently set up for you, it might take a while until your usage is visible. If this persists, please contact support.</p>}
        {!licensingServerClinicNotFound && !!data && <PackageCard
          currentCodes={data.amountTotal - data.amountAvailable}
          maximumCodes={data.amountTotal}
          purchasedPackage={data.status !== 'NONE'}
          expirationDate={!!data.expires ? format(parseISO(data.expires), 'dd.MM.yyyy') : ''}
        />}
        <SectionTitle>Purchase a Package</SectionTitle>
        <ToggleWrapper>
          <Toggle
            onStateLabel="once-off"
            offStateLabel="Monthly"
            onChange={(isOn) => {
              setUseMonthlyPayment(isOn)
            }}
            />
        </ToggleWrapper>
        <CardRowSection>
          {packageArray.map((codePackage, index) => (
            <Card>
              <Title>{codePackage.title}</Title>
              <Amount>{codePackage.amount}</Amount>
              <Description>{codePackage.description}</Description>
              <Cost>{codePackage.cost}</Cost>
              {/*     paymentInfo:
      <React.Fragment><strong>€11,400</strong> once-off or<br /><strong>€950</strong> x 12 monthly payments</React.Fragment>, */}
              <Paragraph>
                <strong>€{formatMoney(codePackage.paymentInfo[useMonthlyPayment ? 'monthly' : 'once'])}</strong>{' '}
                {useMonthlyPayment && 'x 12 monthly payments'}
                {!useMonthlyPayment && 'once-off'}
              </Paragraph>
              <Button isInactive={cartContents.package === index + 1} onAction={() => {
                const _index = index + 1
                setCartContents({ ...cartContents, package: _index === cartContents.package ? 0 : _index})
              }}>
                {cartContents.package === index + 1 ? "Remove from cart" : "Add to cart"}
              </Button>
            </Card>
          ))}
        </CardRowSection>
        <SectionInfo>
          <strong>If you do not have an active plan, or use more patients than currently available in your plan, each patient is billed at €25.</strong>
          The price per package may be paid monthly or all at once. Any unused
          patient licenses will expire after 12 months, unless you purchase a new package, in which case the remaining licenses will be carried over.
          The 12 months start from the date of purchase of the respective package. Already used licenses will have access to all features for at least two years after your last purchase.
        </SectionInfo>
        <SectionTitle>Purchase Add-ons</SectionTitle>
        <CardRowSection>
          {addOnArray.map((addOn) => (
            <Card maxWidth={'33%'}>
              <Title>{addOn.title}</Title>
              <Cost>€{formatMoney(addOn.cost)} / {addOn.costPer}</Cost>
              <NumberPickerWrapper>
                <NumberPicker
                  minimumValue={0}
                  maximumValue={9999}
                  onChange={(val: number) => {
                    const newCartContents = {...cartContents}
                    newCartContents[addOn.licenseType] = val
                    setCartContents(newCartContents)
                  }}
                />
              </NumberPickerWrapper>
              <Subtotal>
                <strong>Subtotal:</strong> €{formatMoney(cartContents[addOn.licenseType] * addOn.cost)}
              </Subtotal>
              {/* <Button onAction={() => console.log("beach")}>Add to cart</Button> */}
            </Card>
          ))}
        </CardRowSection>
        {false && <PurchaseHistorySection>
          <SectionTitle>Purchase History</SectionTitle>
          <PurchaseHistorySectionInner>
            <CategoryWrapper>
              {poCategories.map((category) => (
                <CategoryItem>{category}</CategoryItem>
              ))}
            </CategoryWrapper>
            {poArray.map((invoice) => (
              <PurchaseOrderItem data={invoice} />
            ))}
          </PurchaseHistorySectionInner>
        </PurchaseHistorySection>}
      </React.Fragment>}
      <div ref={overviewSectionRef}>
        {!isLoading && (!!data || licensingServerClinicNotFound) && <React.Fragment>
            <SectionTitle style={{ paddingBottom: 0 }}>Overview</SectionTitle>
            <SectionInfo style={{ paddingTop: 0 }}><strong>Once submitted, your order will be reviewed by our team and we will send an invoice to the email address we have stored.</strong> If your email or billing address has changed, please include it in the comment field.</SectionInfo>
            {(() => {

              let totalPrice = 0
              let dueToday = 0
              let monthly = 0

              const items = Object.entries(cartContents).map(([type, value]) => {
                if (value > 0) {
                  if (type === 'package') {
                    const _package = packageArray[value - 1]
                    const packagePrice = _package.paymentInfo[useMonthlyPayment ? 'monthly' : 'once']
                    totalPrice = totalPrice + packagePrice
                    if (useMonthlyPayment) {
                      monthly = monthly + packagePrice
                      dueToday = dueToday + packagePrice
                    }
                    return { title: `${_package.title} (${useMonthlyPayment ? 'monthly' : 'once-off'})`, amount: 1, unitPrice: _package.paymentInfo.once, totalPrice: packagePrice, totalPriceSuffix: ' x 12' }
                  }
                  const licenseType = addOnArray.find(el => el.licenseType === type)
                  if (!licenseType) {
                    return
                  }
                  totalPrice = totalPrice + licenseType.cost * value
                  dueToday = dueToday + licenseType.cost * value
                  return { title: licenseType.title, amount: value, unitPrice: licenseType.cost, totalPrice: licenseType.cost * value }
                }
                return null
              }).filter(n => n)

              if (items.length === 0) {
                return <SectionInfo>No items selected</SectionInfo>
              }

              let vat = (totalPrice * 1.19) - totalPrice

              const showMonthlyBreakdown = !!monthly

              return <React.Fragment><CartTable cellSpacing={0}>
              <tr>
                <th>Item</th>
                <th style={{ width: '12%' }}>Quantity</th>
                <th style={{ width: '20%' }}>Unit Price</th>
                <th style={{ width: '20%' }}>Total Price</th>
                <th style={{ width: 0 }}></th>
              </tr>
              {items.map(el => {
                if (!!el) {
                  return <tr>
                    <td>{el.title}</td>
                    <td>{el.amount}</td>
                    <td>{formatMoney(el.unitPrice)} EUR</td>
                    <td><strong>{formatMoney(el.totalPrice)} EUR{el.totalPriceSuffix} </strong></td>
                    <td></td>
                  </tr>
                }
              })}
              <tr className="sectionLight">
                <td colSpan={3}>
                    <strong>Subtotal</strong>
                </td>
                <td>
                  {formatMoney(totalPrice)} EUR
                </td>
                <td></td>
              </tr>
              <tr>
                <td colSpan={3}>
                    <strong>VAT (19%)</strong>
                </td>
                <td>
                  {formatMoney(vat)} EUR
                </td>
                <td></td>
              </tr>
              {!showMonthlyBreakdown && <React.Fragment>
                <tr className="section">
                  <td colSpan={3}>
                    <strong>Total</strong>
                  </td>
                  <td>
                    {formatMoney(totalPrice + vat)} EUR
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>}

              {!!showMonthlyBreakdown && <React.Fragment>
                <tr className="section">
                  <td colSpan={3}>
                      <strong>Total due with the next invoice</strong>
                  </td>
                  <td>
                    {formatMoney(dueToday * 1.19)} EUR
                  </td>
                  <td></td>
                </tr>
                <tr>
                  <td colSpan={3}>
                      <strong>Total due for the next 11 months, starting next month</strong>
                  </td>
                  <td>
                    {formatMoney(monthly * 1.19)} EUR
                  </td>
                  <td></td>
                </tr>
              </React.Fragment>}
            </CartTable>
            <div style={{ display: 'flex', justifyContent: 'space-between'}}>
              <div style={{ width: '40%' }}>
                <_TextArea placeholder="Please let us know any comments and requests" onChange={(ev) => {
                  setComment(ev.target.value)
                }}/>
              </div>
              <Button onAction={() => submitOrder()}>
                Submit order
              </Button>
            </div>
          </React.Fragment>
          })()}
        </React.Fragment>}

      </div>
    </ContentWrapper>
  );
}

const Label = styled.div`
  ${Typography.subtitles.subtitleSmall2};
`

const _TextArea = styled.textarea`
  width: 100%;
  border: 0;
  background: ${ColorPaletteMain.backgrounds.grays.background1};
  min-height: 60px;
  border-radius: 12px;
  padding: 12px;
  ${Typography.paragraphsAndCaptions.paragraph1}
`

const ModalTitle = styled.h4`
  ${Typography.headers.h5}
`

const CartTable = styled.table`
  width: 100%;
  th {
    text-align: left;
    ${Typography.subtitles.subtitleSmall2}
  }
  td {
    padding: 16px 0;
    ${Typography.paragraphsAndCaptions.paragraph1}
  }
  .section td {
    border-top: 1px solid ${ColorPaletteMain.primaryColors.ink6};
  }
  .sectionLight td {
    border-top: 1px solid ${ColorPaletteMain.neutralColors.grays.gray0};
  }
  margin-top: 48px;

  border-bottom: 1px solid ${ColorPaletteMain.primaryColors.ink6};
  margin-bottom: 32px;
`


const ToggleWrapper = styled.div`
  max-width: 320px;
  margin-bottom: 24px;
  margin-top: -24px;
`

const CartWrapper = styled.div<{ shown: boolean }>`
  position: fixed;
  bottom: 24px;
  background: ${ColorPaletteMain.neutralColors.blackAndWhite.white0};
  left: ${spacing(NAVBAR_WIDTH + 22)};
  right: ${spacing(12)};
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 28px;
  box-shadow: 0px 4px 15px -3px rgba(0, 0, 0, 0.25);
  border-radius: 12px;
  transform: ${({ shown }) => `translateY(${shown ? '0%' : '200%'})`};
  opacity: ${({ shown }) => shown ? '1' : '0'};
  transition: .5s all;
`

const ContentWrapper = styled.div`
  max-width: 1075px;
  min-width: 700px;
  padding-bottom: 120px;
`

const PurchaseHistorySection = styled.section`
  margin: 100px 0;
`

const PurchaseHistorySectionInner = styled.div`
  display: flex;
  flex-direction: column;
`

const SectionTitle = styled.h5`
  padding: 42px 0;
`

const CardRowSection = styled.div`
  display: flex;
  gap: 24px;
`

const Title = styled.h5`
  font-weight: 300;
  margin: 8px 0;
`

const Description = styled.div`
  padding: 2px 0 6px 0;
`

const Amount = styled.h3`
  font-weight: 800;
`

const Cost = styled.h6`
  padding-top: 25px;
`

const Subtotal = styled.div`
  margin-bottom: 29px;
`

const SectionInfo = styled.p`
  ${Typography.paragraphsAndCaptions.paragraphSmall1};
  padding: 41px 0 0 0;
  max-width: 660px;
`

const NumberPickerWrapper = styled.div`
  height: 100%;
  margin: 31px 0 27px 0;
  width: 100%;
`

const Paragraph = styled.p`
  ${Typography.paragraphsAndCaptions.paragraph1};
  margin: 22px 0 37px 0;
  max-width: 263px;
  text-align: center;
`

const CategoryWrapper = styled.div`
  display: flex;
  align-items: flex-start;
`

const CategoryItem = styled.div`
  ${Typography.subtitles.subtitleSmall2};
  width: 100%;
`